export const groupMessagesBySender = (messages) => {
    const groupedMessages = [];
    let currentGroup = [];

    for (const message of messages) {
        if (currentGroup.length === 0 || currentGroup[0].sender === message.sender) {
            currentGroup.push(message);
        } else {
            groupedMessages.push(currentGroup);
            currentGroup = [message];
        }
    }

    if (currentGroup.length !== 0) {
        groupedMessages.push(currentGroup);
    }

    return groupedMessages;
};

export const validateTrackName = (trackName) => {
    const pattern = /^\d+(\.\s*)?Artist:\s*("[^"]+"|[^;]+);\s*Track:\s*("[^"]+"|[^;]+)$/;

    return pattern.test(trackName);
};

export const convertMsToMinsSecs = (durationMs) => {
    const totalSeconds = Math.floor(durationMs / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;
    return formattedTime;
};

const searchSpotify = async (track, artist, accessToken) => {
    var searchUrl = "https://api.spotify.com/v1/search?q=";

    if (track) {
        searchUrl += encodeURIComponent(`track:${track}`);
    }
    if (artist) {
        if (track) {
            searchUrl += encodeURIComponent(" ");
        }
        searchUrl += encodeURIComponent(`artist:${artist}`);
    }
    searchUrl += "&type=track";

    // console.log(searchUrl);

    const response = await fetch(searchUrl, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    const data = await response.json();
    return data.tracks.items;
};

const substringExists = (searchString, targetString) => {
    const minSubStringLength = searchString.length >= 3 ? 3 : searchString.length;

    // Iterate through each possible substring of searchString
    for (let i = 0; i <= searchString.length - minSubStringLength; i++) {
        for (let j = i + minSubStringLength; j <= searchString.length; j++) {
            const subString = searchString.slice(i, j);
            if (targetString.includes(subString)) {
                return true; // If the substring exists in the target string
            }
        }
    }

    return false; // If no such substring exists
};

const validateResult = (spotifyTrack, track, artist) => {
    // If the track name is a substring of the spotifyTrack name
    // console.log("Validating", spotifyTrack.name, "with", track, artist);
    if (substringExists(track, spotifyTrack.name)) {
        // return true;
        // If the artist name is a substring of the spotifyTrack artist name
        for (let i = 0; i < spotifyTrack.artists.length; i++) {
            if (substringExists(artist, spotifyTrack.artists[i].name)) {
                return true;
            }
        }
    }

    return false;
};

const removeQuotes = (str) => {
    const firstChar = str[0];
    const lastChar = str[str.length - 1];

    if (firstChar === '"' && lastChar === '"') {
        return str.substring(1, str.length - 1);
    }
    return str;
};

//expecting "artist", "track", "accessToken" as params
export const searchTrack = async (params) => {
    if (!params.accessToken) {
        console.log("No access token provided");
        return;
    }

    const searchPatterns = [
        [params.track, params.artist],
        [params.track, undefined],
    ];

    for (let i = 0; i < searchPatterns.length; i++) {
        const [track, artist] = searchPatterns[i];
        const spotifyTracks = await searchSpotify(track, artist, params.accessToken);

        // console.log("Searching for", track, artist);
        // console.log("Spotify tracks:", spotifyTracks);

        for (let j = 0; j < spotifyTracks.length; j++) {
            const spotifyTrack = spotifyTracks[j];
            if (validateResult(spotifyTrack, params.track, params.artist)) {
                return spotifyTrack;
            }
        }
    }
};

function countTokens(text) {
    // Splitting by spaces gives a rough first approximation
    let tokens = text.split(" ");
    let tokenCount = tokens.length;

    // But we need to add extra for any tokens that will be split further by BPE
    for (let token of tokens) {
        // Punctuation attached to words will result in extra tokens
        let extraTokens = token.split(/[,.;!?]/).length - 1;
        // Long words might be split into multiple tokens
        extraTokens += Math.floor(token.length / 15); // very rough approximation
        tokenCount += extraTokens;
    }

    return tokenCount;
}

export const filterMessages = (messages, tokenThreshold) => {
    let newMessages = [];
    let lastUserMsgIndex = -1;
    let assistantMsgContent = "";
    let tokenCount = 0;

    for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i];

        if (message.sender === "assistant") {
            if (message.type === "error") {
                lastUserMsgIndex = -1;
            } else if (assistantMsgContent) {
                assistantMsgContent = message.content + "\n" + assistantMsgContent;
            } else {
                assistantMsgContent = message.content;
            }
            continue;
        }

        if (message.sender === "user") {
            if (lastUserMsgIndex !== -1 && assistantMsgContent) {
                let assistantMessage = { sender: "assistant", content: assistantMsgContent };
                let assistantTokens = countTokens(assistantMsgContent);
                let userMessage = messages[lastUserMsgIndex];
                let userTokens = countTokens(userMessage.content);

                if (tokenCount + assistantTokens + userTokens <= tokenThreshold) {
                    newMessages.unshift(assistantMessage);
                    newMessages.unshift(userMessage);
                    tokenCount += assistantTokens + userTokens;
                } else {
                    break;
                }
                assistantMsgContent = "";
            }
            lastUserMsgIndex = i;
        }
    }

    // If there's still a user message left without a matching assistant message, try to add it
    if (lastUserMsgIndex !== -1 && newMessages.length === 0) {
        let userMessage = messages[lastUserMsgIndex];
        let userTokens = countTokens(userMessage.content);
        if (tokenCount + userTokens <= tokenThreshold) {
            newMessages.unshift(userMessage);
            tokenCount += userTokens;
        }
    }

    // Ensure that the first message in newMessages is from a user
    while (newMessages.length > 0 && newMessages[0].sender !== "user") {
        let removedMessage = newMessages.shift();
        tokenCount -= countTokens(removedMessage.content);
    }

    return newMessages;
};

// export const filterMessages = (messages) => {
//     const newMessages = [];
//     let lastUserMsgIndex = -1;
//     let assistantMsgContent = "";

//     for (let i = 0; i < messages.length; i++) {
//         const message = messages[i];

//         if (message.sender === "user") {
//             if (assistantMsgContent) {
//                 newMessages.push({ sender: "assistant", content: assistantMsgContent }); // push the combined assistant messages
//                 assistantMsgContent = "";
//             }
//             lastUserMsgIndex = i;
//             continue; // don't push it to newMessages array yet
//         }

//         if (message.sender === "assistant") {
//             if (message.type === "error") {
//                 // Skip this message and the last user's message
//                 lastUserMsgIndex = -1;
//             } else if (lastUserMsgIndex !== -1) {
//                 // Push the last user's message and the current message
//                 newMessages.push(messages[lastUserMsgIndex]);
//                 assistantMsgContent = message.content;
//                 lastUserMsgIndex = -1;
//             } else if (assistantMsgContent) {
//                 // If there are consecutive assistant's messages, combine them.
//                 assistantMsgContent += "\n" + message.content;
//             }
//         }
//     }

//     // If there are remaining assistant messages not pushed to newMessages, push them now.
//     if (assistantMsgContent) {
//         newMessages.push({ sender: "assistant", content: assistantMsgContent });
//     }

//     // If the last message is not from the assistant, remove the last user's message
//     if (newMessages.length > 0 && newMessages[newMessages.length - 1].sender !== "assistant") {
//         newMessages.pop();
//     }

//     return newMessages;
// };

// export const filterMessages = (messages) => {
//     const newMessages = [];
//     let lastUserMsgIndex = -1;

//     for (let i = 0; i < messages.length; i++) {
//         const message = messages[i];

//         if (message.sender === "user") {
//             lastUserMsgIndex = i;
//             continue; // don't push it to newMessages array yet
//         }

//         if (message.sender === "assistant") {
//             if (message.type === "error") {
//                 // Skip this message and the last user's message
//                 lastUserMsgIndex = -1;
//             } else if (lastUserMsgIndex !== -1) {
//                 // Push the last user's message and the current message
//                 newMessages.push(messages[lastUserMsgIndex], message);
//                 lastUserMsgIndex = -1;
//             }
//         }
//     }

//     // If the last message is not from the assistant, remove the last user's message
//     if (newMessages.length > 0 && newMessages[newMessages.length - 1].sender !== "assistant") {
//         newMessages.pop();
//     }

//     return newMessages;
// };
