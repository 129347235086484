import { Typography, Stack, Avatar, ListItemContent } from "@mui/joy";
import { useState, useEffect, useRef } from "react";
import { searchTrack } from "./helpers";

const Track = ({ trackName, accessToken, messageId, updateMessage }) => {
    const [status, setStatus] = useState("added");
    const spotifyTrack = useRef(undefined);
    const isSearching = useRef(false);

    const removeBrackets = (str) => {
        // Split the string by ' - '
        let parts = str.split(" - ");

        if (parts[1]) {
            // Remove brackets and content within them
            parts[1] = parts[1].replace(/\s*\(.*?\)\s*/g, "");
        }

        // Reassemble the string
        return parts.join(" - ");
    };

    useEffect(() => {
        const searchSpotifyTrack = async () => {
            if (status !== "found" && !isSearching.current && status !== "not found") {
                isSearching.current = true;

                const pattern = /^\d+(\.\s*)?Artist:\s*("[^"]+"|[^;]+);\s*Track:\s*("[^"]+"|[^;]+)$/;

                const match = pattern.exec(trackName);

                const artist = match[2];
                const track = match[3];

                // console.log("searching for track:", track, "by:", artist);

                // const accessToken = await getAccessToken();

                spotifyTrack.current = await searchTrack({ track, artist, accessToken });
                if (spotifyTrack.current) {
                    updateMessage(messageId, {
                        spotifyTrack: spotifyTrack.current,
                    });
                    setStatus("found");
                } else {
                    setStatus("not found");
                }
            }
            isSearching.current = false;
        };

        searchSpotifyTrack();
    }, [trackName, status]);

    return (
        <>
            {status === "found" && spotifyTrack !== undefined ? (
                <Stack direction="row" spacing={2} alignItems="center">
                    <a href={spotifyTrack.current.album.external_urls.spotify} target="_blank">
                        <Avatar src={spotifyTrack.current.album.images[0].url} variant="album" size="lg" />
                    </a>
                    <ListItemContent>
                        <Stack spacing={0}>
                            <a
                                href={spotifyTrack.current.external_urls.spotify}
                                target="_blank"
                                className="custom-link"
                            >
                                <Typography fontWeight="lg">{spotifyTrack.current.name}</Typography>
                            </a>
                            <Stack direction={"row"} spacing={1}>
                                {spotifyTrack.current.artists.map((artist, index) => (
                                    <a
                                        href={artist.external_urls.spotify}
                                        target="_blank"
                                        className="custom-link"
                                        key={index}
                                    >
                                        <Typography>
                                            {artist.name}
                                            {index !== spotifyTrack.current.artists.length - 1 && ", "}
                                        </Typography>
                                    </a>
                                ))}
                            </Stack>
                        </Stack>
                    </ListItemContent>
                </Stack>
            ) : (
                <Typography>{trackName}</Typography>
            )}
        </>
    );
};

export default Track;
