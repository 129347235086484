import "./App.css";
import { extendTheme, CssVarsProvider, Stack, Typography } from "@mui/joy";
import { useState, useEffect, useRef } from "react";
import { SpotifyLoginButton, getSpotifyLoginUrl } from "./components/SpotifyLoginButton";
import ChatBox from "./components/ChatBox";
import Settings from "./components/Settings";
import SpotifyLogo from "./images/spotifylogo.svg";

function App() {
    const theme = extendTheme({
        components: {
            JoyAvatar: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ...(ownerState.variant === "album" && {
                            borderRadius: "0px",
                        }),
                        ...(ownerState.variant === "avatar" && {
                            borderRadius: "2px",
                            marginLeft: "-42px",
                            marginRight: "10px",
                            backgroundColor: "rgb(128, 138, 141)",
                            fontSize: "12px",
                            fontWeight: "300",
                            color: "white",
                        }),
                    }),
                },
            },
        },
    });

    const [accessToken, setAccessToken] = useState("");
    const [expirationTime, setExpirationTime] = useState("");

    useEffect(() => {
        let hash = window.location.hash;
        let token = window.localStorage.getItem("access_token");
        let expiration_time = window.localStorage.getItem("expiration_time");

        if (hash) {
            token = hash
                .substring(1)
                .split("&")
                .find((elem) => elem.startsWith("access_token"))
                .split("=")[1];

            expiration_time =
                hash
                    .substring(1)
                    .split("&")
                    .find((elem) => elem.startsWith("expires_in"))
                    .split("=")[1] *
                    1000 +
                Date.now();

            window.localStorage.setItem("access_token", token);
            window.localStorage.setItem("expiration_time", expiration_time);
            window.location.hash = "";
        } else if (token && Date.now() >= expiration_time) {
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("expiration_time");
            token = undefined;
            expiration_time = "";
            window.location.replace(getSpotifyLoginUrl());
        }

        setAccessToken(token);
        setExpirationTime(expiration_time);
    }, []);

    return (
        <CssVarsProvider theme={theme}>
            {!accessToken ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <Stack alignItems={"center"} justifyContent={"center"} height={"100vh"} width={"90%"} spacing={4}>
                        <Stack spacing={0} alignItems={"center"}>
                            <Typography fontSize={50} fontWeight={"lg"}>
                                PlaylistGPT
                            </Typography>
                            <Typography fontSize={20}>
                                Made with ChatGPT and Spotify. Discover music. Create playlists.
                            </Typography>
                        </Stack>

                        <SpotifyLoginButton />
                    </Stack>
                </div>
            ) : (
                <div className="chat-container">
                    <div className="header">
                        <img src={SpotifyLogo} alt="Spotify" width={"320px"} className="spotify-logo" />
                        <Settings />
                    </div>
                    <ChatBox accessToken={accessToken} />
                    <div className="bottom-shadow"></div>
                </div>
            )}
        </CssVarsProvider>
    );
}

export default App;
