import { Sheet, Typography, Stack } from "@mui/joy";

const ExamplePrompts = ({ gptQuery }) => {
    const promptExamples = [
        "I'm looking for songs similar to 'Bohemian Rhapsody' by Queen. Can you recommend some?",
        "What are some relaxing classical music pieces to listen to while studying?",
        "I'm throwing a 90s themed party. Can you suggest a playlist?",
        "I love Billie Eilish's dark pop sound. Can you recommend artists with a similar vibe?",
        "What are some empowering female anthems to boost my mood?",
        "I'm a big fan of indie folk music. Can you suggest some lesser-known artists I might enjoy?",
        "Could you recommend some uplifting songs to start my day off right?",
        "I'm interested in exploring jazz music. Where should I start?",
        "What are some must-listen tracks for someone new to the K-pop genre?",
        "Can you recommend some songs for a road trip across the country?",
        "What are some romantic songs perfect for a wedding first dance?",
        "I love the soundtrack from the movie 'Guardians of the Galaxy'. Can you suggest similar songs?",
        "I'm trying to get into opera. Can you recommend some accessible arias or operas for a beginner?",
        "What are some great blues tunes to learn on my new electric guitar?",
        "Can you suggest some songs that are perfect for a summer beach playlist?",
        "I'm looking for ambient electronic music for meditation. Any recommendations?",
        "I'm interested in exploring music from non-English-speaking countries. Can you recommend some tracks?",
        "I'm a fan of classic rock, but I'd like to discover some modern rock bands. Who should I listen to?",
        "What are some good songs to listen to when I'm feeling down and need to uplift my mood?",
        "Can you recommend some essential hip-hop tracks from the last decade?",
    ];

    function getRandomElementsFromArray(array) {
        if (array.length <= 3) {
            return array;
        }

        const shuffledArray = array.slice(); // Create a shallow copy of the input array
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        return shuffledArray.slice(0, 3);
    }

    const randomPrompts = getRandomElementsFromArray(promptExamples);

    const PromptsExamples = ({ prompt, onClick }) => {
        return (
            <Sheet
                variant="outlined"
                sx={{ boxShadow: "0px 0px 5px 5px rgba(0, 0, 0, 0.05)", borderRadius: "8px", cursor: "pointer" }}
                onClick={onClick}
            >
                <Typography sx={{ padding: "20px", textAlign: "center" }} fontSize="13px" color="neutral">
                    {prompt}
                </Typography>
            </Sheet>
        );
    };

    return (
        <div className="example-prompts">
            <Stack direction={"row"} spacing={2} alignItems="center">
                <PromptsExamples prompt={randomPrompts[0]} onClick={() => gptQuery(randomPrompts[0])} />
                <PromptsExamples prompt={randomPrompts[1]} onClick={() => gptQuery(randomPrompts[1])} />
                <PromptsExamples prompt={randomPrompts[2]} onClick={() => gptQuery(randomPrompts[2])} />
            </Stack>
        </div>
    );
};

export default ExamplePrompts;
