import { Box, Button } from "@mui/joy";
import { spotify_client_id, spotify_scopes } from "../settings";

export const getSpotifyLoginUrl = () => {
    const REDIRECT_URI = "https://playlistgpt.ai";
    // const REDIRECT_URI = "http://localhost:3000";
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";

    return `${AUTH_ENDPOINT}?client_id=${spotify_client_id}&scope=${spotify_scopes}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`;
};

export const SpotifyLoginButton = () => {
    return (
        <Box>
            <Button
                variant="solid"
                style={{
                    backgroundColor: "#1DB954",
                    color: "#fff",
                }}
                component="a"
                href={getSpotifyLoginUrl()}
            >
                CONNECT WITH SPOTIFY
            </Button>
        </Box>
    );
};
