import { Typography, Stack } from "@mui/joy";
import { useState } from "react";
import Feedback from "./Feedback";

const Footer = ({ setMessages }) => {
    const [confirmClear, setConfirmClear] = useState(false);

    return (
        <div className="below-sticky-bottom">
            <Stack direction="row" justifyContent="center" spacing={1}>
                {confirmClear ? (
                    <Stack direction={"row"} spacing={1}>
                        <Typography color="neutral" level="body2" fontWeight="sm">
                            Clear?
                        </Typography>
                        <div className="link-style">
                            <Typography
                                color="neutral"
                                level="body2"
                                fontWeight="sm"
                                onClick={() => {
                                    setMessages([]);
                                    window.localStorage.removeItem("messages");
                                    setConfirmClear(false);
                                }}
                            >
                                Yes
                            </Typography>
                        </div>
                        <div className="link-style">
                            <Typography
                                color="neutral"
                                level="body2"
                                fontWeight="sm"
                                onClick={() => setConfirmClear(false)}
                            >
                                Cancel
                            </Typography>
                        </div>
                    </Stack>
                ) : (
                    <div className="link-style">
                        <Typography color="neutral" level="body2" fontWeight="sm" onClick={() => setConfirmClear(true)}>
                            Clear history
                        </Typography>
                    </div>
                )}
                <Typography color="neutral" level="body2" fontWeight="sm">
                    |
                </Typography>
                <Feedback />
            </Stack>
        </div>
    );
};

export default Footer;
