import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/joy";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Button from "@mui/joy/Button";
import { APP_NAME } from "../settings";

const Settings = () => {
    const [open, setOpen] = useState(false);
    const apiKey = useRef(undefined);
    const [selectedModel, setSelectedModel] = useState(undefined);
    const [models, setModels] = useState([]);
    const [apiKeyOk, setApiKeyOk] = useState(false);
    const [howToOpen, setHowToOpen] = useState(false);

    const targetModels = ["gpt-3.5-turbo", "gpt-4"];

    useEffect(() => {
        apiKey.current = localStorage.getItem("apiKey");
        setSelectedModel(localStorage.getItem("model"));

        if (!apiKey.current) {
            setOpen(true);
        }
    }, []);

    const getModels = async () => {
        console.log(apiKey.current);
        if (apiKey.current) {
            try {
                const response = await fetch("https://api.openai.com/v1/models", {
                    method: "GET",
                    headers: { Authorization: `Bearer ${apiKey.current}` },
                });
                const data = await response.json();
                console.log(data);
                const foundModels = targetModels.filter((model) => {
                    return data.data.some((obj) => obj.id === model);
                });
                setApiKeyOk(true);
                setModels(foundModels);
            } catch (error) {
                setApiKeyOk(false);
                console.log(error);
            }
        } else {
            setApiKeyOk(false);
        }
    };

    return (
        <>
            <div className="sticky-header">
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    {selectedModel && <Typography fontSize={12}>{selectedModel}</Typography>}
                    <IconButton
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => {
                            setOpen(true);
                            getModels();
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>
                </Stack>
            </div>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <Stack spacing={2}>
                        <Stack spacing={0}>
                            <Typography fontSize={12}>{APP_NAME} requires an API key from OpenAI.</Typography>
                            <Typography fontSize={12} onClick={() => setHowToOpen(true)} sx={{ cursor: "pointer" }}>
                                <u>How to get one.</u>
                            </Typography>
                        </Stack>
                        <Stack spacing={1} alignItems={"stretch"}>
                            <Typography fontSize={12} fontWeight={"lg"}>
                                OpenAI API Key
                            </Typography>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <Input
                                    placeholder="Enter your OpenAI API key"
                                    size="sm"
                                    fullWidth
                                    value={apiKey.current}
                                    onChange={(event) => {
                                        if (event) {
                                            apiKey.current = event.target.value;
                                            getModels();
                                        }
                                    }}
                                />
                                {apiKeyOk ? <div>✅</div> : <div>❗️</div>}
                            </Stack>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography fontSize={12} fontWeight={"lg"}>
                                Model
                            </Typography>
                            <Select
                                size="sm"
                                defaultValue={selectedModel || models[0]}
                                onChange={(event, newValue) => {
                                    setSelectedModel(newValue);
                                }}
                                placeholder="Select a model"
                            >
                                {models.map((model) => (
                                    <Option value={model} key={model}>
                                        {model}
                                    </Option>
                                ))}
                            </Select>
                        </Stack>
                        <Button
                            size="sm"
                            onClick={() => {
                                if (apiKeyOk && selectedModel) {
                                    window.localStorage.setItem("apiKey", apiKey.current);
                                    localStorage.setItem("model", selectedModel);
                                    setOpen(false);
                                }
                            }}
                        >
                            Save
                        </Button>
                    </Stack>
                    <Modal open={howToOpen} onClose={() => setHowToOpen(false)}>
                        <ModalDialog>
                            <Stack spacing={2}>
                                <Typography fontSize={14}>
                                    <b>How to get OpenAI API Key</b>:
                                </Typography>
                                <Typography fontSize={14}>
                                    <b>1. Create an OpenAI Account: </b>Go to OpenAI's website, then find the 'Sign up'
                                    button and click on it. Follow the instructions to create an account.
                                </Typography>
                                <Typography fontSize={14}>
                                    <b>2. Verify Your Email: </b>
                                    After creating an account, you should receive a verification email. Go to your email
                                    inbox and verify your email address by clicking on the link in the verification
                                    email.
                                </Typography>
                                <Typography fontSize={14}>
                                    <b>3. Access the OpenAI Dashboard: </b>
                                    After verifying your email, log into your OpenAI account. You should be redirected
                                    to your dashboard.
                                </Typography>
                                <Typography fontSize={14}>
                                    <b>4. API Key Generation: </b>
                                    In your dashboard, find the API section. It should be on the left side of your
                                    screen. Click on it.
                                </Typography>
                                <Typography fontSize={14}>
                                    <b>5. Create a New Key: </b>
                                    In the API section, you should see a button labeled 'Create new secret key'. Click
                                    on it and fill out any information required.
                                </Typography>
                            </Stack>
                        </ModalDialog>
                    </Modal>
                </ModalDialog>
            </Modal>
        </>
    );
};

export default Settings;
