import { useState, useRef, useEffect } from "react";
import { List, ListItem, Typography, Avatar, Badge, Button, Stack, Slider } from "@mui/joy";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { SSE } from "sse";
import { PROMPT } from "../settings";
import { filterMessages } from "./helpers";

import Footer from "./Footer";
import Track from "./Track";
import SaveToPlaylist from "./SaveToPlaylist";

import { groupMessagesBySender, validateTrackName, convertMsToMinsSecs } from "./helpers";
import ExamplePrompts from "./ExamplePrompts";

const ChatBox = ({ accessToken }) => {
    const listRef = useRef();

    const playerRef = useRef(undefined);
    const deviceId = useRef(undefined);
    const intervalId = useRef(undefined);

    const [apiKey, setApiKey] = useState(undefined);
    const eventSource = useRef(null);
    const messageId = useRef(undefined);
    const messageContent = useRef("");

    const [messages, setMessages] = useState([]);
    const [currentPlayTime, setCurrentPlayTime] = useState(0);
    const [isPerformingQuery, setIsPerformingQuery] = useState(false);
    const [query, setQuery] = useState("");
    const [model, setModel] = useState("");
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

    useEffect(() => {
        setApiKey(window.localStorage.getItem("apiKey"));
        setModel(window.localStorage.getItem("model"));
        setMessages(JSON.parse(window.localStorage.getItem("messages")) || []);
        setCurrentPlayingTrack(null);
    }, []);

    useEffect(() => {
        // Scroll to the bottom when the list changes
        if (listRef.current && shouldAutoScroll) {
            const element = listRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [messages, shouldAutoScroll]);

    useEffect(() => {
        listRef.current.addEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
        const element = listRef.current;
        const isAtBottom = element.scrollTop + element.clientHeight >= element.scrollHeight;
        setShouldAutoScroll(isAtBottom);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && query && !isPerformingQuery) {
            // Perform your desired action with the input value
            gptQuery(query);
        }
    };

    const gptQuery = async (query) => {
        setQuery("");

        addMessage("user", query);

        setIsPerformingQuery(true);
        messageId.current = undefined;

        const filteredMessages = filterMessages(messages, 512);

        const messagesToSend = [
            { role: "user", content: PROMPT },
            { role: "assistant", content: "Ok" },
            ...filteredMessages.map((msg) => {
                return { role: msg.sender, content: msg.content };
            }),
            { role: "user", content: query },
        ];

        // console.log(messagesToSend);

        const url = "https://api.openai.com/v1/chat/completions";
        const data = {
            model: model,
            messages: messagesToSend,
            temperature: 0.75,
            max_tokens: 2048,
            stream: true,
        };

        eventSource.current = new SSE(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
            },
            method: "POST",
            payload: JSON.stringify(data),
        });

        eventSource.current.addEventListener("message", (e) => {
            if (e.data != "[DONE]") {
                const payload = JSON.parse(e.data);
                const token = payload?.choices[0]?.delta?.content;

                if (!messageId.current) {
                    messageId.current = addMessage("assistant");
                    messageContent.current = "";
                }

                if (token) {
                    messageContent.current += token;
                    updateMessage(messageId.current, { content: messageContent.current });

                    if (token[token.length - 1] === "\n") {
                        const trimmedMessage = messageContent.current.trimEnd();

                        updateMessage(messageId.current, {
                            content: trimmedMessage,
                            received: true,
                        });
                        messageId.current = undefined;
                    }
                }
            } else {
                if (messageId.current && messageContent.current.length > 0) {
                    updateMessage(messageId.current, {
                        content: messageContent.current,
                        received: true,
                    });
                }

                eventSource.current.close();
            }
        });

        eventSource.current.addEventListener("readystatechange", (e) => {
            if (e.readyState >= 2) {
                setIsPerformingQuery(false);
            }
        });

        eventSource.current.addEventListener("error", (e) => {
            console.error("Error occurred:", e);
            addMessage("assistant", "Sorry, something went wrong. Please try again.", true, "error");
            eventSource.current.close();
        });

        eventSource.current.stream();
    };

    const addMessage = (sender, content, received, type) => {
        const id = uuidv4();
        setMessages((prevMessages) => {
            const newMessage = {
                id: id,
                sender: sender,
                content: content || "",
                received: received || false,
                type: type || "normal",
                isPaused: true,
            };
            const newMsgArray = [...prevMessages, newMessage];
            window.localStorage.setItem("messages", JSON.stringify(newMsgArray));
            return newMsgArray;
        });
        return id;
    };

    const updateMessage = (id, updatedMessage) => {
        setMessages((prevMessages) => {
            const newMsgArray = prevMessages.map((message) => {
                if (message.id === id) {
                    return { ...message, ...updatedMessage };
                }
                return message;
            });
            window.localStorage.setItem("messages", JSON.stringify(newMsgArray));
            return newMsgArray;
        });
    };

    const loadSpotifyWebPlayer = async () => {
        // console.log("Loading Spotify Web Player");
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "https://sdk.scdn.co/spotify-player.js";
            script.async = true;

            window.onSpotifyWebPlaybackSDKReady = () => {
                const player = new window.Spotify.Player({
                    name: "GPT Playlist",
                    getOAuthToken: (cb) => {
                        cb(accessToken);
                    },
                    volume: 0.5,
                });

                player.addListener("ready", ({ device_id }) => {
                    // console.log("Ready with Device ID", device_id);
                    resolve({ player: player, device_id: device_id });
                });

                player.addListener("not_ready", ({ device_id }) => {
                    // console.log("Device ID has gone offline", device_id);
                    reject(new Error("Device ID has gone offline"));
                });

                player.addListener("player_state_changed", (state) => {
                    if (!state) {
                        return;
                    }
                    // console.log("Player state changed", state);
                    // console.log(intervalId.current);
                    if (!state.paused && !intervalId.current) {
                        setCurrentPlayTime(state.position);
                        // Start the timer
                        intervalId.current = setInterval(() => {
                            setCurrentPlayTime((currentPlayTime) => currentPlayTime + 1000);
                        }, 1000);
                    }

                    if (state.paused && intervalId.current) {
                        clearInterval(intervalId.current);
                        setPlayButtonIsPaused(true);
                        intervalId.current = null;
                    }
                });

                player.connect();
            };

            script.onload = () => {
                console.log("Spotify SDK script loaded successfully");
            };

            script.onerror = () => {
                reject(new Error("Failed to load Spotify web player script"));
            };

            document.body.appendChild(script);
        });
    };

    const startPlayback = async (trackId) => {
        setCurrentPlayTime(0);
        // console.log(playerRef.current, deviceId.current);
        if (!playerRef.current) {
            const { player, device_id } = await loadSpotifyWebPlayer();
            playerRef.current = player;
            deviceId.current = device_id;
        }
        // const accessToken = await getAccessToken();
        try {
            fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId.current}`, {
                method: "PUT",
                body: JSON.stringify({
                    uris: [`spotify:track:${trackId}`],
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            updateMessage(messageId, { playbackInfo: { isPlaying: true } });
        } catch (error) {
            console.log("Error starting playback", error);
        }
    };

    const setPlayButtonIsPaused = (isPaused) => {
        setMessages((prevMessages) => {
            return prevMessages.map((message) => {
                if (message.isPlaying) {
                    return { ...message, isPaused: isPaused };
                }
                return message;
            });
        });
    };

    const playButtonClicked = (message) => {
        if (message.isPlaying) {
            playerRef.current.togglePlay();
            updateMessage(message.id, { isPaused: !message.isPaused });
        } else {
            startPlayback(message.spotifyTrack.id);
            setCurrentPlayingTrack(message.id);
        }
    };

    const setCurrentPlayingTrack = (id) => {
        setMessages((prevMessages) => {
            return prevMessages.map((message) => {
                if (message.id === id) {
                    return { ...message, ...{ isPlaying: true, isPaused: false } };
                }
                return { ...message, ...{ isPlaying: false, isPaused: true } };
            });
        });
    };

    return (
        <>
            <div className="chat-box" ref={listRef}>
                <List>
                    {groupMessagesBySender(messages).map((group, groupIndex) => {
                        const messageStyle = group[0].sender === "user" ? "user-message" : "bot-message";
                        const spotifyTracks = group.map((message) => message.spotifyTrack).filter(Boolean);
                        return (
                            <div key={groupIndex} className={`${messageStyle} message-group`}>
                                {group.map((message, index) => (
                                    <ListItem key={index} className="message">
                                        {index === 0 &&
                                            (message.sender === "assistant" && message.type === "error" ? (
                                                <Badge
                                                    color="danger"
                                                    badgeContent="!"
                                                    size="sm"
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "right",
                                                    }}
                                                    badgeInset="0px 10px"
                                                >
                                                    <Avatar
                                                        variant="avatar"
                                                        sx={{
                                                            "--Avatar-size": "30px",
                                                        }}
                                                        src="../images/logo1.png"
                                                    >
                                                        SE
                                                    </Avatar>
                                                </Badge>
                                            ) : message.sender === "assistant" ? (
                                                <Avatar
                                                    variant="avatar"
                                                    sx={{
                                                        "--Avatar-size": "30px",
                                                    }}
                                                >
                                                    GP
                                                </Avatar>
                                            ) : (
                                                <Avatar
                                                    variant="avatar"
                                                    sx={{
                                                        "--Avatar-size": "30px",
                                                    }}
                                                >
                                                    SE
                                                </Avatar>
                                            ))}
                                        <div className="message-content">
                                            {message.sender === "assistant" && message.type === "error" ? (
                                                <Typography color="danger" variant="plain" fontSize="sm">
                                                    {message.content}
                                                </Typography>
                                            ) : message.sender === "assistant" &&
                                              message.received &&
                                              validateTrackName(message.content) ? (
                                                <Stack direction="column">
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                    >
                                                        {message.spotifyTrack && (
                                                            <IconButton
                                                                variant="plain"
                                                                color="neutral"
                                                                size="sm"
                                                                onClick={() => {
                                                                    playButtonClicked(message);
                                                                }}
                                                            >
                                                                {message.isPaused ? <PlayArrowIcon /> : <PauseIcon />}
                                                            </IconButton>
                                                        )}

                                                        <Track
                                                            trackName={message.content}
                                                            accessToken={accessToken}
                                                            messageId={message.id}
                                                            updateMessage={updateMessage}
                                                        />
                                                    </Stack>
                                                    {message.isPlaying && (
                                                        <Stack direction={"row"} spacing={2} alignItems="center">
                                                            <Typography fontSize="sm">
                                                                {convertMsToMinsSecs(currentPlayTime)}
                                                            </Typography>
                                                            <Slider
                                                                size="sm"
                                                                color="neutral"
                                                                min={0}
                                                                max={message.spotifyTrack.duration_ms}
                                                                value={currentPlayTime}
                                                                onChange={(e, newValue) => {
                                                                    setCurrentPlayTime(newValue);
                                                                }}
                                                                onChangeCommitted={(e, newValue) => {
                                                                    playerRef.current?.seek(newValue);
                                                                }}
                                                            />
                                                            <Typography fontSize="sm">
                                                                {message.spotifyTrack &&
                                                                    convertMsToMinsSecs(
                                                                        message.spotifyTrack.duration_ms
                                                                    )}
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            ) : (
                                                <Typography>{message.content}</Typography>
                                            )}
                                        </div>
                                    </ListItem>
                                ))}
                                {group[0].sender === "assistant" && !isPerformingQuery && spotifyTracks.length > 0 && (
                                    <div className="message">
                                        <div className="message-content playlist-save">
                                            <SaveToPlaylist accessToken={accessToken} tracks={spotifyTracks} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </List>
            </div>

            {messages.length === 0 && query.length === 0 && <ExamplePrompts gptQuery={gptQuery} />}

            <div className="sticky-bottom">
                {isPerformingQuery ? (
                    <Stack>
                        <Button
                            color="neutral"
                            variant="outlined"
                            onClick={() => {
                                if (eventSource.current) {
                                    eventSource.current.close();
                                    eventSource.current = null;
                                }
                                setIsPerformingQuery(false);
                            }}
                        >
                            Stop generating
                        </Button>
                    </Stack>
                ) : (
                    <Textarea
                        placeholder="Send a message..."
                        value={query}
                        sx={{ width: "100%" }}
                        size="lg"
                        maxRows={10}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyPress={handleKeyPress}
                        // disabled={isPerformingQuery}
                    />
                )}
            </div>
            <Footer setMessages={setMessages} />
        </>
    );
};

export default ChatBox;
