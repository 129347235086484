import Modal from "@mui/joy/Modal";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { Button } from "@mui/joy/";

import { useState } from "react";
import { Stack } from "@mui/joy";

const Feedback = () => {
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [thankYou, setThankYou] = useState(false);
    const SERVER_URI = "http://profitars.org:5001";

    return (
        <div>
            <div className="link-style">
                <Typography color="neutral" level="body2" fontWeight="sm" onClick={() => setOpen(true)}>
                    Feedback
                </Typography>
            </div>
            <Modal
                open={open}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                onClose={(_event, reason) => {
                    if (thankYou) {
                        setThankYou(false);
                    }
                    setOpen(false);
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: 400,
                        borderRadius: "md",
                        p: 3,
                        boxShadow: "lg",
                    }}
                >
                    <Stack spacing={2} justifyContent={"center"}>
                        {thankYou ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography level="h4">Thank you!</Typography>
                            </div>
                        ) : (
                            <>
                                <Typography
                                    component="h2"
                                    id="modal-title"
                                    level="h4"
                                    textColor="inherit"
                                    fontWeight="lg"
                                    mb={1}
                                >
                                    Send Feedback
                                </Typography>
                                <Textarea
                                    minRows={4}
                                    maxRows={4}
                                    color="neutral"
                                    onChange={(e) => setFeedback(e.target.value)}
                                />
                                <Stack direction="column" justifyContent={"center"} spacing={1} alignItems={"stretch"}>
                                    <Button
                                        variant="plain"
                                        color="neutral"
                                        onClick={() => {
                                            try {
                                                fetch(
                                                    `${SERVER_URI}/feedback?feedback=${encodeURIComponent(feedback)}`
                                                );
                                            } catch (e) {}
                                            setThankYou(true);
                                        }}
                                    >
                                        Send
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Sheet>
            </Modal>
        </div>
    );
};

export default Feedback;
