import React from "react";
import axios from "axios";
import { Button, Stack, Autocomplete, Typography } from "@mui/joy";
import { useState, useEffect } from "react";

function SaveToPlaylist({ tracks, accessToken }) {
    const [playlists, setPlaylists] = useState([]);
    const [saveToPlaylstName, setSaveToPlaylistName] = useState("");
    const [saveButtonText, setSaveButtonText] = useState("Save");
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
    const [open, setOpen] = useState(false);

    const loadPlaylists = async () => {
        try {
            // Make a GET request to fetch the user's playlists
            // const token = await getAccessToken();
            const response = await axios.get("https://api.spotify.com/v1/me/playlists", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            // Extract the playlist names from the response
            setPlaylists(response.data.items);
        } catch (error) {
            console.error("Error fetching playlists:", error);
        }
    };

    useEffect(() => {
        loadPlaylists();
    }, []);

    const createNewPlaylist = async (name) => {
        // Create a new playlist
        console.log(name);
        try {
            // const token = await getAccessToken();
            const createResponse = await axios.post(
                "https://api.spotify.com/v1/me/playlists",
                {
                    name: name,
                    public: false,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            return createResponse.data.id;
        } catch (error) {
            console.error("Error creating or getting playlist:", error);
        }
    };

    const getPlaylistId = async (name) => {
        await loadPlaylists();
        const foundItem = playlists.find((item) => item.name === name);
        return foundItem ? foundItem.id : await createNewPlaylist(name);
    };

    const addToExistingPlaylist = async (playlistId) => {
        try {
            // Add tracks to the existing playlist
            // const token = await getAccessToken();
            await axios.post(
                `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
                {
                    uris: tracks.map((track) => `spotify:track:${track.id}`),
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("Tracks added to playlist successfully!");
        } catch (error) {
            console.error("Error adding tracks to playlist:", error);
        }
    };

    const handleSaveToPlaylistClick = async () => {
        if (tracks.length !== 0) {
            setSaveButtonEnabled(false);
            setSaveButtonText("Saving...");
            console.log(tracks);

            const playlistId = await getPlaylistId(saveToPlaylstName);
            await addToExistingPlaylist(playlistId);

            setSaveButtonText("Save");
            setSaveButtonEnabled(true);
        }
    };

    return (
        <>
            {open ? (
                <Stack spacing={1} direction={"row"}>
                    <Autocomplete
                        // sx={{ width: 300 }}
                        options={playlists.map((playlist) => playlist.name)}
                        placeholder="Playlist name"
                        freeSolo={true}
                        size="sm"
                        onInputChange={(event, newInputValue) => {
                            setSaveToPlaylistName(newInputValue);
                        }}
                    />
                    <Button
                        onClick={handleSaveToPlaylistClick}
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={saveToPlaylstName === "" || tracks.length === 0 || !saveButtonEnabled ? true : false}
                    >
                        {saveButtonText}
                    </Button>
                </Stack>
            ) : (
                <div className="link-style">
                    <Typography color="neutral" fontSize={14} onClick={() => setOpen(true)}>
                        save to playlist
                    </Typography>
                </div>
            )}
        </>
    );
}

export default SaveToPlaylist;
