export const spotify_client_id = "4b6a1f96410a476c804dff1a82298504";
export const spotify_scopes =
    "user-read-playback-state,user-modify-playback-state,user-read-currently-playing,streaming,playlist-read-private,playlist-modify-private,playlist-read-collaborative,playlist-modify-public";
export const PROMPT =
    "You are acting as a music advisor. \
Your task is to provide music tracks recommendations or answer music-related questions based on user's query. \
Disregard queries not related to music. \
If user is asking for tracks start answer with comment on user's query. \
When providing music tracks always use following format: <Number. Artist:[Artist Name]; Track:[Track Name]>.";
export const APP_NAME = "PlaylistGPT";
